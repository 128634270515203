import React, { useEffect, useState } from 'react'
import Routes from './routes'
/*import "fontsource-roboto/300.css"
import "fontsource-roboto/400.css"
import "fontsource-roboto/500.css"
import "fontsource-roboto/700.css"*/

import './assets/css/bootstrap.min.css'
import './assets/fonts/gillsansmt/font.css'
//import "./assets/fonts/fontawesome/css/all.min.css";
import ReactPixel from 'react-facebook-pixel'
import './assets/css/react-carousel.es.css'
import './assets/css/style.css'
import { CartProvider } from './contexts/Cart'
import { LeadProvider } from './contexts/Lead'
import { PagSeguroProvider } from './contexts/PagSeguro'
import { RepImagesProvider } from './contexts/RepImages'
import { ShopProvider } from './contexts/Shop'
import { UserProvider } from './contexts/User'
import Cart from './data/Cart'
import Lead from './data/Lead'
import PagSeguro from './data/PagSeguro'
import User from './data/User'

import { ThemeProvider } from '@material-ui/core/styles'
import CircularLoading from './components/ScreenLoading/CircularLoading'
import { getShopAndMount } from './services/api/shop'
import defaultTheme from './utils/muiTheme'
import TagManager from 'react-gtm-module'

const gtmTagID = document.getElementById('gtm-tag-loja-dk')?.value || null;

const tagManagerArgs = {
  gtmId: gtmTagID
}

if(tagManagerArgs.gtmId){
  TagManager.initialize(tagManagerArgs);
}

window.PagSeguroDirectPayment = window.PagSeguroDirectPayment || {}

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}

ReactPixel.init('496158061376662', {}, options)

const user = new User()
user.setUser()
const cart = new Cart()
cart.refresh()
const pagSeguro = new PagSeguro()
const lead = new Lead()

function App() {
  const [shop, setShop] = useState()
  const theme = shop?.conf?.palette
    ? {
        ...defaultTheme,
        palette: {
          ...defaultTheme.palette,
          ...shop?.conf?.palette,
          background: { ...shop?.conf?.palette?.background, paper: shop?.conf?.palette.background.primary },
        },
        overrides: {
          ...defaultTheme.overrides,
          MuiPaper: {
            root: {
              background: shop?.conf?.palette.background.primary,
            },
          },
        },
      }
    : defaultTheme
  useEffect(() => {
    ;(async function () {
      try {
        if (!shop) {
          const resp = await getShopAndMount()
          setShop(resp)
        }
      } catch (error) {
        throw error
      }
    })()
  }, [])

  if (!shop) return <CircularLoading />
  return (
    <ThemeProvider theme={theme}>
      <ShopProvider value={shop}>
        <UserProvider value={user}>
          <CartProvider value={cart}>
            <RepImagesProvider value="/template/drika_2/dist">
              <PagSeguroProvider value={pagSeguro}>
                <LeadProvider value={lead}>
                  <div className="App" style={{ background: theme.palette.background.default }}>
                    <Routes userData={user} cartData={cart} shopData={shop} />
                  </div>
                </LeadProvider>
              </PagSeguroProvider>
            </RepImagesProvider>
          </CartProvider>
        </UserProvider>
      </ShopProvider>
    </ThemeProvider>
  )
}

export default App
