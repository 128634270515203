import React, { useEffect, useState, lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { getShop } from './services/api/shop'
import CircularLoading from './components/ScreenLoading/CircularLoading'
import { WidgetContextProvider } from './contexts/WidgetContext'


const PrivateRoute = lazy(() => import('./privateRoutes'))
const ScrollToTop = lazy(() => import('./utils/ScrollToTop'))
const Catalog = lazy(() => import('./pages/Catalog'))
const Offers = lazy(() => import('./pages/Offers'))
const Catalogos = lazy(() => import('./pages/Catalogos'))
const Catalogo = lazy(() => import('./pages/Catalogo'))
const NotFound = lazy(() => import('./pages/NotFound'))

//const Home = lazy(() => import('./pages/Home'))
const PageBody = lazy(() => import('./pages/page-widget/PageBody'))
const Product = lazy(() => import('./pages/Product'))
const Auth = lazy(() => import('./pages/Auth'))
const Register = lazy(() => import('./pages/Register'))
const EmailValidation = lazy(() => import('./pages/EmailValidation'))
const EmailConfirmed = lazy(() => import('./pages/EmailConfirmed'))
const Search = lazy(() => import('./pages/Search'))
const Categorys = lazy(() => import('./pages/Categorys'))
const CartPage = lazy(() => import('./pages/Cart'))
const CheckoutAddress = lazy(() => import('./pages/checkout/Address'))
const CheckoutPayment = lazy(() => import('./pages/checkout/Payment'))
const OrderConfirmation = lazy(() => import('./pages/checkout/OrderConfirmation'))
const AccountPage = lazy(() => import('./pages/account/Account'))
const OrdersPage = lazy(() => import('./pages/account/Orders'))
//const AboutPage = lazy(() => import('./pages/About'))
//const TermsPage = lazy(() => import('./pages/Terms'))
//const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicy'))
//const ExchangePolicyPage = lazy(() => import('./pages/ExchangePolicy'))
//const PortfolioPage = lazy(() => import('./pages/Portfolio'))
//const ContactsPage = lazy(() => import('./pages/contact/Contacts'))
const OrderPage = lazy(() => import('./pages/account/Order'))
const ResetPassword = lazy(() => import('./pages/ResetPassword'))
const SendArt = lazy(() => import('./pages/checkout/SendArt'))
const PersonalData = lazy(() => import('./pages/checkout/PersonalData'))
const PersonPage = lazy(() => import('./pages/account/Person'))
const RegistrationSuccess = lazy(() => import('./pages/RegistrationSuccess'))
const EmailAlreadyInUse = lazy(() => import('./pages/EmailAlreadyInUse'))

const Routes = ({ userData, cartData, shopData }) => {
  const [shop, setShop] = useState(null)

  useEffect(() => {
    const loadShop = async () => {
      try {
        const resp = await getShop()
        if (resp?.data) setShop(resp.data)
      } catch (error) {
        console.log(error)
        return false
      }
    }

    loadShop()
  }, [])

  return (
    <React.StrictMode>
      <Suspense fallback={<CircularLoading />}>
        <BrowserRouter>
          <WidgetContextProvider>
            <ScrollToTop>
              <Switch>
                {/* <Route
                  path="/"
                  exact={true}
                  component={(props) => <PageBody shop={shop} cartData={cartData} {...props} />}
                /> */}

                {/* <Route
                  path="/sobre"
                  exact={true}
                  component={(props) => <AboutPage cartData={cartData} shop={shop} {...props} />}
                /> */}

                {/* <Route
                  path="/termos_e_condicoes"
                  exact={true}
                  component={(props) => <TermsPage cartData={cartData} shop={shop} {...props} />}
                /> */}

                {/* <Route
                  path="/termos"
                  exact={true}
                  component={(props) => <TermsPage cartData={cartData} shop={shop} {...props} />}
                /> */}

                {/* <Route
                  path="/politicas_de_privacidade"
                  exact={true}
                  component={(props) => <PrivacyPolicyPage cartData={cartData} shop={shop} {...props} />}
                /> */}

                {/* <Route
                  path="/politica_de_privacidade"
                  exact={true}
                  component={(props) => <PrivacyPolicyPage cartData={cartData} shop={shop} {...props} />}
                /> */}

                {/* <Route
                  path="/politica_de_troca_e_devolucao"
                  exact={true}
                  component={(props) => <ExchangePolicyPage cartData={cartData} shop={shop} {...props} />}
                /> */}

                {/* <Route
                  path="/portfolio"
                  exact={true}
                  component={(props) => <PortfolioPage cartData={cartData} shop={shop} {...props} />}
                /> */}

                {/* <Route
                  path="/contato"
                  exact={true}
                  component={(props) => <ContactsPage cartData={cartData} shop={shop} {...props} />}
                /> */}

                {/* <Route
                  path="/contatos"
                  exact={true}
                  component={(props) => <ContactsPage cartData={cartData} shop={shop} {...props} />}
                /> */}

                {/*#region  ROTAS FIXAS*/}
                <Route
                  path="/p/:subcategoria/:id"
                  exact={true}
                  component={(props) => <Product shop={shop} cartData={cartData} {...props} />}
                />
                <Route
                  path="/produto/:id"
                  exact={true}
                  component={(props) => <Product shop={shop} cartData={cartData} {...props} />}
                />
                <Route
                  path="/p/:id"
                  exact={true}
                  component={(props) => <Product shop={shop} cartData={cartData} {...props} />}
                />

                <Route
                  path="/product/:id"
                  exact={true}
                  component={(props) => <Product shop={shop} cartData={cartData} {...props} />}
                />
                <Route
                  path="/busca"
                  exact={true}
                  component={(props) => <Search shop={shop} cartData={cartData} {...props} />}
                />
                <Route
                  path="/busca/:term"
                  exact={true}
                  component={(props) => <Search shop={shop} cartData={cartData} {...props} />}
                />
                <Route
                  path="/search/:term"
                  exact={true}
                  component={(props) => <Search shop={shop} cartData={cartData} {...props} />}
                />

                <Route
                  path="/carrinho"
                  exact={true}
                  component={(props) => <CartPage cartData={cartData} shop={shop} {...props} />}
                />
                <Route
                  path="/cart"
                  exact={true}
                  component={(props) => <CartPage cartData={cartData} shop={shop} {...props} />}
                />

                <PrivateRoute path="/checkout/envio-arte" exact={true} component={(props) => <SendArt {...props} />} />
                <PrivateRoute
                  path="/checkout/dados-pessoais"
                  exact={true}
                  component={(props) => <PersonalData {...props} />}
                />
                <PrivateRoute
                  path="/checkout/endereco"
                  exact={true}
                  component={(props) => (
                    <CheckoutAddress userData={userData} cartData={cartData} shop={shop} {...props} />
                  )}
                />
                <PrivateRoute
                  path="/checkout/pagamento"
                  exact={true}
                  component={(props) => (
                    <CheckoutPayment
                      userData={userData}
                      cartData={cartData}
                      shopData={shopData}
                      shop={shop}
                      {...props}
                    />
                  )}
                />

                <PrivateRoute
                  path="/checkout/confirmacao"
                  exact={true}
                  component={(props) => <OrderConfirmation {...props} />}
                />

                <PrivateRoute
                  path="/conta"
                  exact={true}
                  component={(props) => (
                    <AccountPage userData={userData} cartData={cartData} shopData={shopData} shop={shop} {...props} />
                  )}
                />

                <PrivateRoute
                  path="/compras"
                  exact={true}
                  component={(props) => (
                    <OrdersPage userData={userData} cartData={cartData} shopData={shopData} shop={shop} {...props} />
                  )}
                />
                <PrivateRoute
                  path="/pedidos"
                  exact={true}
                  component={(props) => (
                    <OrdersPage userData={userData} cartData={cartData} shopData={shopData} shop={shop} {...props} />
                  )}
                />
                <PrivateRoute
                  path="/pedido/:id"
                  exact={true}
                  component={(props) => (
                    <OrderPage userData={userData} cartData={cartData} shopData={shopData} shop={shop} {...props} />
                  )}
                />
                <PrivateRoute
                  path="/conta/compras"
                  exact={true}
                  component={(props) => (
                    <OrdersPage userData={userData} cartData={cartData} shopData={shopData} shop={shop} {...props} />
                  )}
                />
                <PrivateRoute
                  path="/conta/pedidos"
                  exact={true}
                  component={(props) => (
                    <OrdersPage userData={userData} cartData={cartData} shopData={shopData} shop={shop} {...props} />
                  )}
                />
                <PrivateRoute path="/conta/pessoa/:id" exact={true} component={(props) => <PersonPage {...props} />} />

                <Route
                  path="/categorias/:category"
                  exact={true}
                  component={(props) => <Categorys cartData={cartData} shop={shop} {...props} />}
                />
                <Route
                  path="/categorias/:category/:subcategory"
                  exact={true}
                  component={(props) => <Categorys cartData={cartData} shop={shop} {...props} />}
                />

                <Route
                  path="/categoria/:category"
                  exact={true}
                  component={(props) => <Categorys cartData={cartData} shop={shop} {...props} />}
                />
                <Route
                  path="/categoria/:category/:subcategory"
                  exact={true}
                  component={(props) => <Categorys cartData={cartData} shop={shop} {...props} />}
                />

                <Route
                  path="/category/:category"
                  exact={true}
                  component={(props) => <Categorys cartData={cartData} shop={shop} {...props} />}
                />
                <Route
                  path="/category/:category/:subcategory"
                  exact={true}
                  component={(props) => <Categorys cartData={cartData} shop={shop} {...props} />}
                />

                <Route
                  path="/categorys/:category"
                  exact={true}
                  component={(props) => <Categorys cartData={cartData} shop={shop} {...props} />}
                />
                <Route
                  path="/categorys/:category/:subcategory"
                  exact={true}
                  component={(props) => <Categorys cartData={cartData} shop={shop} {...props} />}
                />

                <Route path="/entrar" exact={true} component={Auth} />
                <Route path="/login" exact={true} component={Auth} />
                <Route path="/registro" exact={true} component={Register} />
                <Route path="/register" exact={true} component={Register} />
                <Route path="/confirmacao-email" exact={true} component={EmailValidation} />
                <Route path="/redefinir-senha" exact={true} component={ResetPassword} />
                <Route path="/404" exact={true} component={NotFound} />
                <PrivateRoute path="/email-confirmation" exact={true} component={EmailValidation} />
                <PrivateRoute path="/account-created" exact={true} component={EmailConfirmed} />

                {/* #endregion */}
                <Route
                  path="/catalogo"
                  exact={true}
                  component={(props) => <Catalog cartData={cartData} shop={shop} {...props} />}
                />
                
                <Route
                  path="/ofertas"
                  exact={true}
                  component={(props) => <Offers cartData={cartData} shop={shop} {...props} />}
                />
                <Route path="/cadastro-realizado-com-sucesso" exact={true} component={RegistrationSuccess} />

                <Route path="/email-ja-cadastrado" exact={true} component={EmailAlreadyInUse} />

                <Route path="/catalogos" exact={true} shop={shop} component={Catalogos} />
                <Route path="/catalogo/:id" exact={true} component={Catalogo} />

                <Route path="*" component={(props) => <PageBody shop={shop} cartData={cartData} {...props} />} />
              </Switch>
            </ScrollToTop>
          </WidgetContextProvider>
        </BrowserRouter>
      </Suspense>
    </React.StrictMode>
  )
}

export default Routes
